import { colors as cbexLightColors } from "./cbex-light.colours";
import { colors as scevLightColors } from "./scev-light.colours";

export enum ViewModes {
  cbexLight = "cbexLight",
  scevLight = "scevLight",
}

export type Brand = ViewModes.cbexLight | ViewModes.scevLight | undefined;

export const getBrandColors = (brand?: Brand) => {
  switch (brand) {
    case ViewModes.cbexLight:
      return cbexLightColors;
    case ViewModes.scevLight:
      return scevLightColors;
    default:
      return cbexLightColors;
  }
};
