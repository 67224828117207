import { AppColors, AppPalette, CustomPalette } from "@bpipartners/webui";

const appColors: AppColors = {
  primary: "#006970",
  surfaceTint: "#006970",
  onPrimary: "#FFFFFF",
  primaryContainer: "#9DF0F8",
  onPrimaryContainer: "#002022",
  secondary: "#006B5E",
  onSecondary: "#FFFFFF",
  secondaryContainer: "#9FF2E1",
  onSecondaryContainer: "#00201B",
  tertiary: "#8A5022",
  onTertiary: "#FFFFFF",
  tertiaryContainer: "#FFDCC5",
  onTertiaryContainer: "#301400",
  error: "#BA1A1A",
  onError: "#FFFFFF",
  errorContainer: "#FFDAD6",
  onErrorContainer: "#410002",
  background: "#F4FAFB",
  onBackground: "#161D1D",
  surface: "#F4FAFB",
  onSurface: "#161D1D",
  surfaceVariant: "#DAE4E5",
  onSurfaceVariant: "#3F4849",
  outline: "#6F797A",
  outlineVariant: "#BEC8C9",
  shadow: "#000000",
  scrim: "#000000",
  inverseSurface: "#2B3232",
  inverseOnSurface: "#ECF2F2",
  inversePrimary: "#80D4DC",
  primaryFixed: "#9DF0F8",
  onPrimaryFixed: "#002022",
  primaryFixedDim: "#80D4DC",
  onPrimaryFixedVariant: "#004F54",
  secondaryFixed: "#9FF2E1",
  onSecondaryFixed: "#00201B",
  secondaryFixedDim: "#83D5C5",
  onSecondaryFixedVariant: "#005046",
  tertiaryFixed: "#FFDCC5",
  onTertiaryFixed: "#301400",
  tertiaryFixedDim: "#FFB784",
  onTertiaryFixedVariant: "#6D390B",
  surfaceDim: "#D5DBDB",
  surfaceBright: "#F4FAFB",
  surfaceContainerLowest: "#FFFFFF",
  surfaceContainerLow: "#EFF5F5",
  surfaceContainer: "#E9EFEF",
  surfaceContainerHigh: "#E3E9EA",
  surfaceContainerHighest: "#DEE4E4",
  backdrop: "#000000",
  relation: { organization: "#0B79D0", person: "#C77700", tag: "#C51162" },
  planning: {
    // planningRowBackground: "#F4F5F4",
  },
  importance: {
    major: { fg: "#d82e18", bg: "#f2bdb4" },
    medium: { fg: "#db7c2e", bg: "#EAAF71" },
    normal: { fg: "#6b6f73", bg: "#edf1f4" },
    low: { fg: "#01a2fa", bg: "#c6e9ff" },
  },
  timesheet: {
    green: "",
    orange: "",
  },
  customColors: {
    cbexResult: "#1C6252",
    cbexFail: "#BB482F",
    cbexSuccess: "#4CAF50",

    cbexRequested: "#F07F12",
    cbexConfirmed: "#F07F12",
    cbexResultsCollected: "#F07F12",
    raportTableColors: {
      domain: "#e5d7f4",
      goal: "#f5effb",
      measurement: "#faf7fd",
    },
    measurementLevels: {
      1: "#d82e18",
      2: "#db7c2e",
      3: "#CCA400",
      4: "#8FB302",
      5: "#3D9901",
    },
  },
};

const appPalette: AppPalette = {
  primary: {
    0: "#000000",
    5: "#001416",
    10: "#002022",
    // 15: "#002B2E",
    20: "#00363A",
    25: "#004347",
    30: "#004F54",
    35: "#0A5B61",
    40: "#1F686E",
    50: "#3D8187",
    60: "#599BA1",
    70: "#74B6BC",
    80: "#8FD2D8",
    90: "#ABEEF5",
    95: "#C7FAFF",
    98: "#E9FDFF",
    99: "#F4FEFF",
    100: "#FFFFFF",
  },
  secondary: {
    0: "#000000",
    5: "#001411",
    10: "#00201B",
    // 15: "#002C25",
    20: "#003730",
    25: "#00443B",
    30: "#005046",
    35: "#005D52",
    40: "#006B5E",
    50: "#278576",
    60: "#469F90",
    70: "#63BAAA",
    80: "#7FD6C5",
    90: "#9BF3E1",
    95: "#B5FFEF",
    98: "#E5FFF8",
    99: "#F3FFFB",
    100: "#FFFFFF",
  },
  tertiary: {
    0: "#000000",
    5: "#200B00",
    10: "#301400",
    // 15: "#3F1C00",
    20: "#4F2500",
    25: "#602E00",
    30: "#713700",
    35: "#824100",
    40: "#944A00",
    50: "#B95F00",
    60: "#F07F12",
    70: "#FF8E2C",
    80: "#FFB784",
    90: "#FFDCC5",
    95: "#FFEDE4",
    98: "#FFF8F5",
    99: "#FFFBFF",
    100: "#FFFFFF",
  },
  neutral: {
    0: "#000000",
    5: "#0F1111",
    10: "#1A1C1C",
    // 15: "#242626",
    20: "#2F3131",
    25: "#3A3C3C",
    30: "#454747",
    35: "#515353",
    40: "#5D5F5F",
    50: "#767777",
    60: "#909191",
    70: "#ABABAB",
    80: "#C6C6C6",
    90: "#E2E2E2",
    95: "#F1F1F0",
    98: "#F9F9F9",
    99: "#FCFCFC",
    100: "#FFFFFF",
  },
  neutralVariant: {
    0: "#000000",
    5: "#0D1212",
    10: "#171C1D",
    // 15: "#222727",
    20: "#2C3132",
    25: "#373C3D",
    30: "#434848",
    35: "#4E5354",
    40: "#5A5F60",
    50: "#737878",
    60: "#8D9292",
    70: "#A7ACAC",
    80: "#C3C7C8",
    90: "#DFE3E3",
    95: "#EDF2F2",
    98: "#F6FAFA",
    99: "#F9FDFD",
    100: "#FFFFFF",
  },

  error: [
    "#000000",
    null,
    null,
    null,
    "#280001",
    "#2d0001",
    "#310001",
    null,
    null,
    null,
    "#410002",
    null,
    "#490002",
    null,
    null,
    null,
    null,
    "#5c0004",
    null,
    null,
    "#690005",
    null,
    "#710005",
    null,
    "#790006",
    "#7e0007",
    null,
    null,
    null,
    null,
    "#93000a",
    null,
    null,
    null,
    null,
    "#a80710",
    null,
    null,
    null,
    null,
    "#ba1a1a",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#de3730",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ff5449",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ff897d",
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    null,
    "#ffb4ab",
    null,
    null,
    null,
    null,
    null,
    null,
    "#ffcfc9",
    null,
    null,
    "#ffdad6",
    null,
    "#ffe2de",
    null,
    "#ffe9e6",
    "#ffedea",
    "#fff0ee",
    null,
    "#fff8f7",
    "#fffbff",
    "#ffffff",
  ],
};

export const colors: CustomPalette = {
  appColors,
  appPalette,
  palette: {
    // type: "light",
    background: {
      default: "#FFF",
      paper: appColors.surfaceContainerLow,
    },
    primary: {
      light: appColors.primary,
      main: appPalette.primary[60],
      dark: appColors.primary,
      contrastText: appColors.onPrimary,
    },
    secondary: {
      light: appColors.secondary,
      main: appPalette.secondary[60],
      dark: appColors.secondary,
      contrastText: appColors.onSecondary,
    },
  },
};

// menu color: secondaryContainer
// hover color:
// selected color:
