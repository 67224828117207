import { createTheme } from "@mui/material/styles";
import { CustomPalette } from "../brand/brand.model";

export const getMaterialTheme = ({ palette, appColors }: CustomPalette) =>
  createTheme({
    palette,
    spacing: 4,
    components: {
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            borderRadius: 12,
            padding: 0,
            paddingTop: 8,
            paddingBottom: 8,
            borderColor: appColors?.outlineVariant,
          },
        },
      },
    },
  });
