const margin = 8;
const topbarHeight = 60;
const breadCrumbHeight = 48;
// const breadcrumbMargin = 32;
const appDrawerWidth = 260;
const appDrawerItemHeight = 45;
// const pageDrawerWidth = 230;
// const pageDrawerWidthSmall = 150;
const minimumPageWidth = 800;
const minimumPageHeight = 1200;
const homeGridHeight = 400;
// (windowSize.height - 10 - theme.dimensions.totalTopbarHeight) / 2;

export const dimensions = {
  // sidebarWidth: 80,
  // sidebarCollapsedWidth: 60,
  // sidebarTop: 90,
  // sidebarCollapseButtonHeight: 24,
  // heightAppBar: 80,
  // heightFooter1: 84,
  // heightFooter2: 56,
  // heightSearchList: 360,
  // actionsHeight: 40,
  headerFontSize: 14,
  // treeNodeIndentationWidth: 15,
  // treeNodeHeight: 32,
  // generalPadding: 5,
  // generalMargin: 5,
  topbarHeight: topbarHeight,
  breadCrumbHeight: breadCrumbHeight,
  totalTopbarHeight: breadCrumbHeight + 4 * margin + topbarHeight,
  // pageDrawerWidth: pageDrawerWidth,
  // pageDrawerWidthSmall: pageDrawerWidthSmall,
  appDrawerWidth: appDrawerWidth,
  appDrawerItemHeight: appDrawerItemHeight,
  totalAppDrawerWidth: appDrawerWidth + margin,
  // totalAppDrawerWithPageDrawer: appDrawerWidth + pageDrawerWidth + margin * 3,
  margin: margin,
  heightAppBar: topbarHeight,
  minimumPageWidth: minimumPageWidth,
  minimumPageHeight: minimumPageHeight,
  homeGridHeight: homeGridHeight,
};

export const getDimensions = () => dimensions;
