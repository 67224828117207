import { getBrandColors, Brand } from "./brand";
import { dimensions } from "./settings";
import { flexHelpers } from "./helpers";
import { getMaterialTheme } from "./material";
import React from "react";
import { useTheme as useThemeReact } from "@bpipartners/webui";
import { createTheme } from "@bpipartners/webui";
import { colors } from "./brand/cbex-light.colours";

export const getScevTheme = (brand?: Brand) => {
  const { appColors, palette } = getBrandColors(brand);
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line
  const materialTheme = getMaterialTheme({ palette } as any);
  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors,
    dimensions,
    flexHelpers,
  };
};

export type ScevTheme = ReturnType<typeof getScevTheme>;

// ------------------------------------------------------------------------------

export const getTheme = () => {
  const palette = colors.palette;
  const appColors = colors.appColors;
  // any because materialTheme typing expecting everything
  // eslint-disable-next-line

  const materialTheme = createTheme({
    spacing: 4,
    components: {
      MuiPaper: {
        defaultProps: {
          variant: "outlined",
        },
        styleOverrides: {
          root: {
            // borderRadius: 12,
            padding: 0,
            paddingTop: 8,
            paddingBottom: 8,
            backgroundColor: appColors.surfaceContainerLowest,
          },
        },
      },
      MuiAppBar: {
        styleOverrides: {
          root: {
            borderRadius: 0,
          },
        },
      },
      MuiButton: {
        defaultProps: {
          size: "medium",
        },
        styleOverrides: {
          root: {
            borderRadius: 24,
            height: 36,
            textTransform: "none",
            // background: colors.appColors.tertiary,
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          root: {
            background: appColors.surfaceContainerLow,
            "&.Mui-disabled": {
              background: appColors.surfaceContainerLow,
              color: "#dadada",
            },
          },
        },
      },
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInputBase-root": {
              backgroundColor: "inherit",
            },
            background: appColors.surfaceContainerLow,
            "&.Mui-disabled": {
              background: appColors.surfaceContainerLow,
              color: "#dadada",
            },
          },
        },
      },
    },
    palette,
  });
  return {
    ...materialTheme,
    // Override material default: show header above drawer (sidebar)
    zIndex: {
      ...materialTheme.zIndex,
      appBar: materialTheme.zIndex.drawer,
      drawer: materialTheme.zIndex.appBar,
    },
    appColors: colors.appColors,
    appPalette: colors.appPalette,
    dimensions,
  };
};

export type Theme = ReturnType<typeof getTheme>;

export const useTheme = () => useThemeReact() as Theme;
