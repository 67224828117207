import { ScevAppColors, CustomPalette } from "./scev-brand.model";

// don't export this.
const scevColors = {
  primaryLight: "#b085f5",
  primary: "#704EC0",
  primaryDark: "#4d2c91",
  organizationColor: "#0B79D0",
  personColor: "#C77700",
  secondaryLight: "#b085f5",
  secondary: "#704EC0",
  secondaryDark: "#4d2c91",
  white: "#FFFFFF",
  paleGrey: "rgba(0, 0, 0, 0.6)",
  whitesmoke: "#F9F9F9",
  relationTag: "#C51162",
  primaryLighter: "#f1f7f8",
  error: "#d32f2f",
  contentHover: "rgba(63, 81, 181, 0.08)",
  formGrey: "#f0f0f0",
  alertOrange: "#FFA500",
  black: "#000000",
  fontColour: "#757575",
  fontColourDarkGrey: "#212B31",
  actionableColor: "#729f33",
  timesheetGreen: "rgba(123, 198, 126, 1)",
  timesheetOrange: "rgba(237, 108, 2, 1)",
  dataGridHeader: "#C3D6E2",
  background: "#212B31",
  scevResult: "#1C6252",
  scevFail: "#BB482F",
  scevSuccess: "#4CAF50",
  scevRequested: "#7DC1AD",
  scevConfirmed: "#4D907E",
  scevResultsCollected: "#1C6252",
};

// colors used by all brands
const appColors: ScevAppColors = {
  actionable: scevColors.actionableColor,
  relationTag: scevColors.relationTag,
  background: scevColors.background,
  formControl: scevColors.formGrey,
  alertOrange: scevColors.alertOrange,
  disabledBackgroundColor: scevColors.formGrey,
  timesheetGreen: scevColors.timesheetGreen,
  scevResult: scevColors.scevResult,
  timesheetOrange: scevColors.timesheetOrange,
  contentHover: scevColors.contentHover,
  sidebar: scevColors.white,
  error: scevColors.error,
  blackText: scevColors.black,
  fontColour: scevColors.fontColour,
  fontColourDarkGrey: scevColors.fontColourDarkGrey,
  topBar: scevColors.white,
  organization: scevColors.organizationColor,
  person: scevColors.personColor,
  contentBackground: {
    subtle: scevColors.white,
    default: scevColors.whitesmoke,
    primary: scevColors.primaryLighter,
  },
  dataGridHeader: scevColors.dataGridHeader,
  scevFail: scevColors.scevFail,
  scevSuccess: scevColors.scevSuccess,
  scevRequested: scevColors.scevRequested,
  scevConfirmed: scevColors.scevConfirmed,
  scevResultsCollected: scevColors.scevResultsCollected,
  outlineVariant: scevColors.formGrey,
};

export const colors: CustomPalette = {
  appColors,
  organizationColor: scevColors.organizationColor,
  personColor: scevColors.personColor,
  palette: {
    //@ts-ignore
    type: "light",
    background: {
      default: scevColors.whitesmoke,
      paper: scevColors.white,
    },
    primary: {
      light: scevColors.primaryLight,
      main: scevColors.primary,
      dark: scevColors.primaryDark,
      contrastText: scevColors.white,
    },
    secondary: {
      light: scevColors.secondaryLight,
      main: scevColors.secondary,
      dark: scevColors.secondaryDark,
      contrastText: scevColors.white,
    },
    info: {
      light: scevColors.secondaryLight,
      main: scevColors.secondary,
      dark: scevColors.secondaryDark,
    },
  },
};
